import { InitAppForPageFn } from '@wix/yoshi-flow-editor';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => {
  return {
    memoryStorage: platformApis.storage.memory,
    sessionStorage: platformApis.storage.session,
  };
};
